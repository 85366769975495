import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { TiTick } from "react-icons/ti";

import { MdDeleteOutline } from "react-icons/md";
import { CiEdit, CiLock, CiUnlock } from "react-icons/ci";
import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import TestModal from './TestModal';


const TestManagement = () => {
  const childRef = useRef();
  const [tests, setTests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [checked, setChecked] = useState(tests);
  const [unchecked, setUnChecked] = useState(true);
  const [loadTests, setTestLoading] = useState(false);
  const [totalActiveBatches, setTotalActiveBatches] = useState(0);
  const [totalInactiveBatches, setTotalInactiveBatches] = useState(0);
  const [totalArchivedBatches, setTotalArchivedBatches] = useState(0);


  // search functionality    
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    const filteredTests = tests?.filter(
      item => item?.testName?.toLowerCase().includes(searchTerm?.toLowerCase())
    );


    setChecked(filteredTests)
  }, [searchTerm])
  const handleSearch = (e) => {
    setSearchTerm(e.target.value)
  }

  //set Table data
  useEffect(() => {
    axios.get(BACKEND_SERVER_URL + '/getTests').then((res) => {
      if (res.data.tests) {

        let tests = res.data.tests;  //recieving batch here
        setTotalActiveBatches(tests.filter(test => test.status == 1 && test.hide == 0).length)
        setTotalInactiveBatches(tests.filter(test => test.status == 0 && test.hide == 0).length)
        setTotalArchivedBatches(tests.filter(test => test.hide == 1).length)
        tests = tests.filter(test => test.hide == 0)
        setTests(tests);
        setChecked(tests);

      }
    })
  }, [loadTests])

  //console.log('Checked ', checked)
  //console.log(unchecked)
  const handleChecked = (id) => {
    let temp = checked.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setChecked(temp);
  };
  const handleCheckedAll = (value) => {
    let temp = checked.map((data) => {
      return { ...data, inputchecked: value };
    });
    console.log('Temp', temp)
    setChecked(temp);
    setUnChecked(!unchecked);
  };

  const pageSize = 15;
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const records = checked.slice(firstIndex, lastIndex);

  const npage = Math.ceil(checked.length / pageSize)
  const number = [...Array(npage + 1).keys()].slice(1)
  console.log(records)
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1)
    }
  }
  //handle delete course
  const handleDeleteBatches = (testId) => {
    swal({
      title: "Are you sure?",
      text:
        "Once deleted, you will not be able to recover this test",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.delete(BACKEND_SERVER_URL + '/deletetest/' + testId).then((res) => {
          if (res) {
            swal("Poof! Your test has been deleted!", {
              icon: "success",
            });
            setTestLoading(!loadTests);
          }
        })
      }
    })
  }

  const getSn = (index) => {
    return tests.length - index - ((currentPage - 1) * pageSize);
  }

  const handleBatchSave = async (batchname, batchdetails, sortId, id = 0) => {
    console.log(id)
    try {
      if (id <= 0) {
        const res = await axios.post(BACKEND_SERVER_URL + '/createBatch', { batchTitle: batchname, batchDetails: batchdetails, sortId: sortId || 0 });
        if (res?.data) {
          console.log('Course api ', res?.data);
          toast.success(res.data.message);
          setTestLoading(!loadTests);
        }
      } else {
        const res = await axios.put(BACKEND_SERVER_URL + '/updateBatch/' + id, { batchTitle: batchname, batchDetails: batchdetails, sortId: sortId || 0 });
        if (res?.data) {
          console.log('Course api ', res?.data);
          toast.success(res.data.message);
          setTestLoading(!loadTests);
        }
      }

    } catch (error) {
      console.error("An error occurred during the request:", error.message);

    }
  }

  const handleUploadFile = async (selectedFile) => {
    const formData = new FormData();
    formData.append('uploadedFile', selectedFile, selectedFile.name);

    axios.post(BACKEND_SERVER_URL + '/uploadBatches', formData)
      .then((response) => {
        if (response) {
          toast.success(response.data.message);
          setTestLoading(!loadTests);
        }
      })
      .catch(error => {
        toast.error(error.message);
      });
  }


  //common bulk handler
  const commonHandler = (action) => {
    let ids = records.filter(record => record.inputchecked).map(record => record.id);
    if (ids.length <= 0) {
      toast.error('Please select atleast one tests');
      return;
    }
    const postData = { ids: ids };
    switch (action) {
      case "deleteBulk":
        swal({
          title: "Are you sure?",
          text:
            "Once deleted, you will not be able to recover these tests",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            axios.post(BACKEND_SERVER_URL + '/deleteTests', postData).then((response) => {
              if (response) {
                toast.success(response.data.message);
                setTestLoading(!loadTests)
              }
            }).catch((error) => {
              toast.error(error.message);
            });

          }
        })
        break;
      case "activateBulk":
        swal({
          title: "Are you sure?",
          text:
            "Do you really want to activate these tests",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            axios.post(BACKEND_SERVER_URL + '/activateTests', postData).then((response) => {
              if (response) {
                toast.success(response.data.message);
                setTestLoading(!loadTests)
              }
            }).catch((error) => {
              toast.error(error.message);
            });

          }
        })
        break;
      case "InActivateBulk":
        swal({
          title: "Are you sure?",
          text:
            "Do you really want to inactivate these Tests",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            axios.post(BACKEND_SERVER_URL + '/inactivateTests', postData).then((response) => {
              if (response) {
                toast.success(response.data.message);
                setTestLoading(!loadTests)
              }
            }).catch((error) => {
              toast.error(error.message);
            });

          }
        })
        break;
    }
  }
  const handleTabClick = (action) => {
    if (action === "activeTab") {
      let active = tests?.filter(batch => batch.status == 1 && batch.hide == 0)
      setChecked(active)

    } else {
      let inactive = tests?.filter(batch => batch.status == 0 && batch.hide == 0);
      setChecked(inactive)

    }
  }
  //handle toggle
  const togglePaidTestIRHandler = async (id, field, value, index) => {

    try {
      const res = await axios.put(`http://localhost:8000/${field === 'paidTest' ? 'changePaidTestState' : 'changeInstantResult'}/${id}`, { value: value })
      let newTests = [...tests]

      let newTest = newTests.find(test => test.id == id);
      newTest[field] = value == 1 ? 0 : 1;

      setTests(newTests);
      setChecked(newTests);
      toast.success(res.data.message);

    } catch (error) {
      toast.error(error);
    }
  }
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className=" page-title ">{/*  flex-wrap  */}
                <div className='d-flex justify-content-between'>
                  <Link to='/add-test'>
                    <button type="button" className="btn btn-primary "
                      onClick={() => childRef.current.openModal("add course")}
                    >
                      + Add Test
                    </button>
                  </Link>
                  {/*  <button type="button" className="btn btn-primary ms-3"
                    onClick={() => childRef.current.openModal("upload course file")}
                  >
                    + Import Courses
                  </button> */}
                </div>
                <div className="input-group search-area mb-md-0 mb-3">
                  <input type="text" className="form-control" onChange={handleSearch} value={searchTerm} placeholder="Search here..." />
                  <span className="input-group-text"><Link to={"#"}>
                    <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z" fill="#01A3FF" />
                    </svg>
                  </Link></span>
                </div>

              </div>
              <div className='page-title flex-wrap  mt-1'>
                <div className="col-sm-4">
                  <button onClick={() => handleTabClick('activeTab')} type="button" className="btn btn-success btn-sm  me-5 ps-0 pe-3 "
                    style={{ width: "50%", }}>
                    Active: {totalActiveBatches}
                  </button>
                </div>
                <div className="col-sm-4">
                  <button onClick={() => handleTabClick('inActiveTab')} type="button" className="btn btn-warning btn-sm ms-1 me-5 ps-0  "
                    style={{ width: "50%", }}>
                    Inactive: {totalInactiveBatches}
                  </button>
                </div>
                <div className="col-sm-4">
                  <button type="button" className="btn btn-danger btn-sm ms-1 me-5 ps-0 "
                    style={{ width: "50%" }}>
                    Archive: {totalArchivedBatches}
                  </button>
                </div>

              </div>
            </div>
            <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
              <div className="dataTables_wrapper no-footer">
                <div className="d-sm-flex text-center justify-content-between align-items-center">
                  <div>
                    <Button variant="danger ms-3" size='xs' onClick={(e) => commonHandler("deleteBulk")}>Delete</Button>
                    <Button variant="success ms-3 " size='xs' onClick={(e) => commonHandler("activateBulk")}>Active</Button>
                    <Button variant="warning ms-3" size='xs' onClick={(e) => commonHandler("InActivateBulk")}>Inactive</Button>
                  </div>
                  <div className='dataTables_info'>
                    Records: {records.length}
                  </div>
                  <div className="dataTables_paginate paging_simple_numbers justify-content-center"
                    id="example-student_wrapper">
                    <Link
                      className="paginate_button previous disabled"
                      to="#"
                      onClick={prePage}
                    >
                      <i className="fa-solid fa-angle-left" />
                    </Link>
                    <span>
                      {number.map((n, i) => (
                        <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                          onClick={() => changeCPage(n)}
                        >
                          {n}

                        </Link>
                      ))}
                    </span>
                    <Link
                      className="paginate_button next"
                      to="#"
                      onClick={nextPage}
                    >
                      <i className="fa-solid fa-angle-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive full-data">
                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">



                  <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0" id="example-student">
                    <thead>
                      <tr className='text-center'>
                        <th>SN</th>
                        <th>
                          <input type="checkbox" className="form-check-input" id="checkAll"
                            onClick={() => handleCheckedAll(unchecked)}
                          />
                        </th>
                        <th>Status</th>
                        <th className='text-start'>Test Name</th>
                        <th className='text-start'>Paid Test</th>
                        <th className='text-start'>IR</th>
                        <th className='text-start'>Total Question.</th>
                        <th className='text-start'>Total Sections.</th>
                        <th className='text-start'>Total Marks.</th>
                        <th className='text-start'>Start Date.</th>
                        <th className='text-start'>End Date.</th>
                        <th className='text-start'>Start Time.</th>
                        <th className='text-start'>End Time.</th>
                        <th className='text-start'>Result</th>
                        <th>Priority</th>
                        <th className='text-start'>Created On</th>
                        <th className='text-start'>Count</th>
                        <th className='text-start'>Questions</th>

                      </tr>
                    </thead>
                    <tbody>
                      {records.map((item, ind) => (
                        <tr key={ind} className={`${ind % 2 == 1 ? 'table-active' : ''}`}>
                          <td className='text-center'><span className="text-primary font-w600   "> {getSn(ind)}</span></td>
                          <td className='text-center'>
                            <div className="checkbox me-0 align-self-center">
                              <div className="custom-control custom-checkbox ">
                                <input type="checkbox"
                                  className="form-check-input"
                                  id={`stud-${item.id}`}
                                  checked={item.inputchecked}
                                  onChange={() => handleChecked(item.id)}
                                />
                                <label className="custom-control-label" htmlFor={`stud-${item.id}`}></label>
                              </div>
                            </div>
                          </td>
                          <td className='text-center'>
                            {item.status == 1 && <span className="text-success font-w600 ">Active </span>}
                            {item.status == 0 && <span className="text-warning font-w600 ">Inactive </span>}
                          </td>

                          <td>
                            <div className="trans-list sweetalert">
                              <h4>{item.testName}{' '}

                                <CiEdit cursor='pointer' title='Edit Test' size={20} className='ms-3' onClick={() => childRef.current.openModal(item)} />

                                <MdDeleteOutline title='Delete Test' cursor='pointer' size={20} className='ms-2' onClick={() => { handleDeleteBatches(item.id) }}

                                />
                              </h4>
                            </div>
                          </td>
                          <td>
                            <h6 className="mb-0 form-check form-switch" ><input className="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckDefault" style={{ width: "40px", height: "20px" }} checked={item.paidTest} onChange={() => togglePaidTestIRHandler(item.id, "paidTest", item.paidTest, ind)} /></h6>
                          </td>
                          <td>
                            <h6 className="mb-0 form-check form-switch" ><input className="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckDefault" style={{ width: "40px", height: "20px" }} checked={item.instantResult} onChange={() => togglePaidTestIRHandler(item.id, "instantResult", item.instantResult, ind)} /></h6>
                          </td>
                          <td><h6 className="mb-0">{item.totalQuestions}</h6></td>
                          <td><h6 className="mb-0">{item.totalSection}</h6></td>
                          <td><h6 className="mb-0">{item.totalMarks}</h6></td>
                          <td><h6 className="mb-0">{item.startDate}</h6></td>
                          <td><h6 className="mb-0">{item.endDate}</h6></td>
                          <td><h6 className="mb-0">{item.startTime}</h6></td>
                          <td><h6 className="mb-0">{item.endTime}</h6></td>
                          <td><h6 className="mb-0"></h6></td>
                          <td className='text-center'><h6 className="mb-0">{item.priority}</h6></td>
                          <td className='text-center'>
                            <div className="date">{new Date(item.createdOn).toLocaleDateString('en-GB')}</div>
                          </td>
                          <td><h6 className="mb-0"></h6></td>
                          <td><h6 className="mb-0">
                            <Link to='/test-management'>View</Link>
                          </h6></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TestModal ref={childRef} />
    </>
  )
}

export default TestManagement;
