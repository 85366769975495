import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import BatchSampleFile from '../../../download files/batch_sample_file.docx'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import AddNewTest from '../TestManagement/AddNewTest';



const BatchModal = forwardRef((props, ref) => {
    console.log('Batchmodal')
    const [modalBox, setModalBox] = useState(false);
    //new course
    const [batchname, setNewBatchName] = useState('');
    const [batchdetails, setNewbatchDetails] = useState('');
    const [sortId, setNewBatchSortID] = useState(0);
    const [modal, setModal] = useState("")
    const [id, setId] = useState();
    const [isBatchExisted, setIsBatchExisted] = useState(false);
    console.log(batchname, batchdetails, sortId)


    useImperativeHandle(ref, () => ({
        openModal(data, item = {}) {
            setIsBatchExisted(false);
            setNewBatchName(item?.batchTitle)
            setNewBatchSortID(item?.sortId)
            setNewbatchDetails(item?.batchDetails)
            setId(item.id);
            setModal(data);
            setModalBox(true);
        }
    }));

    const handleBatchNameChange = (name) => {
        setNewBatchName(name);
        axios.post(BACKEND_SERVER_URL + '/checkExistingBatch', { batchTitle: name })
            .then((resposne) => {
                console.log(resposne.data);
                setIsBatchExisted(resposne.data.existed);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    //handle create  course
    const handleSaveBatch = async () => {
        await commonCallBackForSaveUpdate();
    };
    //handle updte course
    const handleUpdateBatch = async () => {
        await commonCallBackForSaveUpdate(id);
    }

    const commonCallBackForSaveUpdate = async (id = 0) => {
        if ((batchname === "" || batchname === undefined)) {
            toast.error("Please Enter batch name ");
        } else if (isBatchExisted) {
            toast.error("Batch already existed for this admin")
        } else {
            console.log(batchname, batchdetails)
            await props.saveCallBack(batchname, batchdetails || '', sortId, id);
            setModalBox(false);
        }
        //  await props.saveCallBack(batchname, batchdetails, sortId, id);
    }


    //file handling
    const [selectedFile, setSelectedFile] = useState(null);
    // console.log(selectedFile)
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        console.log(selectedFile)

    }
    const handleUpload = async () => {
        console.log(selectedFile)
        if (selectedFile == null) {
            toast.error("Please select file")
        } else {
            await props.uploadCallBack(selectedFile);
            setModalBox(false);
            setSelectedFile(null);
        }
    }




    return (
        <> 
        
         {
            
            modal === "add course" &&
            <Modal onHide={setModalBox} show={modalBox} centered>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add New Batch</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                                <AddNewTest/>
                        <div className="mb-3 d-block">
                            <label htmlFor="basic-url" className="form-label d-block">Batch Name</label>
                            <input type="text" className="form-control w-100" placeholder="Enter Batch Name" onChange={(e) => handleBatchNameChange(e.target.value)} />

                            {batchname?.length == 0 && <p className='text-danger'>This field cannot be blank</p>}
                            {isBatchExisted && <p className='text-danger'>This batch already exists for this admin</p>}

                        </div>
                        <div className="mb-3 d-block">
                            <label htmlFor="basic-url" className="form-label d-block">Batch details</label>
                            <textarea className='form-control' name="" id="" cols="50" rows="4" onChange={(e) => setNewbatchDetails(e.target.value)} />

                            {/*  {batchname?.length == 0 && <p className='text-danger'>This field cannot be blank</p>}
                            {isBatchExisted && <p className='text-danger'>This batch already exists for this admin</p>} */}

                        </div>
                        <div className="mb-3 d-block">
                            <label htmlFor="basic-url" className="form-label d-block">Priority</label>
                            <input value={sortId ?? 0} type="number" className="form-control w-100" placeholder="Enter priority for batch" onChange={(e) => setNewBatchSortID(e.target.value)} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger light" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleSaveBatch}>Create</button>
                    </div>
                </div>
            </Modal>}
            {
                modal === "upload course file" &&
                <Modal onHide={setModalBox} show={modalBox} centered>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Import Batches file</h5>
                            <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 d-block">
                                <a href={BatchSampleFile} download="batch_sample_file.docx" ><Button variant="outline-info" size='sm' title='Click here to download the sample file'>Download Sample file </Button> </a>
                            </div>
                            <div className="mb-3 d-block">
                                <label htmlFor="basic-url" className="form-label d-block">Upload file</label>
                                <input type="file" className="form-control w-100" onChange={handleFileChange} accept=".docx" placeholder="Enter Course Title" />
                                {selectedFile == null ? < p className='text-danger'>Please select file</p> : null}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger light" onClick={() => setModalBox(false)}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleUpload}>Upload file</button>
                        </div>
                    </div>
                </Modal >}
            {
                modal === "update course" &&

                <Modal onHide={setModalBox} show={modalBox} centered>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update Batch</h5>
                            <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 d-block">
                                <label htmlFor="basic-url" className="form-label d-block">Batch Name</label>
                                <input type="text" className="form-control w-100" placeholder="Enter Course Title" value={batchname} onChange={(e) => handleBatchNameChange(e.target.value)} />
                            </div>
                            {!batchname && <p className='text-danger'>This field cannot be blank</p>}
                            {isBatchExisted && <p className='text-danger'>This course already exists for this admin</p>}
                            <div className="mb-3 d-block">
                                <label htmlFor="basic-url" className="form-label d-block">Batch details</label>
                                <textarea value={batchdetails} className='form-control' name="" id="" cols="50" rows="4" onChange={(e) => setNewbatchDetails(e.target.value)}> </textarea>

                                {/*  {batchname?.length == 0 && <p className='text-danger'>This field cannot be blank</p>}
                            {isBatchExisted && <p className='text-danger'>This batch already exists for this admin</p>} */}

                            </div>
                            <div className="mb-3 d-block">
                                <label htmlFor="basic-url" className="form-label d-block">Priority</label>
                                <input type="number" className="form-control w-100" placeholder="Enter sorting number for course" value={sortId} onChange={(e) => setNewBatchSortID(e.target.value)} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger light" onClick={() => setModalBox(false)}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleUpdateBatch}>Update</button>
                        </div>
                    </div>
                </Modal>}
        </>
    )
})
export default BatchModal;