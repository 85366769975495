import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import BatchSampleFile from '../../../download files/batch_sample_file.docx'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import AddNewTest from '../TestManagement/AddNewTest';
import './TestModal.scss'


const TestModal = forwardRef((props, ref) => {
    console.log('Batchmodal')
    const [modalBox, setModalBox] = useState(false);
    //new course

    const [id, setId] = useState();
    const [test, setTest] = useState();
    //console.log(id, test)


    useImperativeHandle(ref, () => ({
        openModal(item ) {
            console.log(item)
            setId(item.id);
            setModalBox(true);
            setTest(item)
        }
    }));


    return (
        <>


            <Modal onHide={setModalBox} show={modalBox} centered>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Update Test</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <AddNewTest test={test} />
                    </div>
                    <div className="modal-footer d-flex-start">
                        <button type="button" className="btn btn-danger light" onClick={() => setModalBox(false)}>Close</button>
                    
                    </div>

                </div>
            </Modal>
        </>
    )
})
export default TestModal;