import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import BatchModal from './BatchModal';
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BACKEND_SERVER_URL } from '../../../http/http-config';


const BatchManagement = () => {
    const childRef = useRef();
    const [batches, setBatches] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState(batches);
    const [unchecked, setUnChecked] = useState(true);
    const [loadBatches, setBatchLoading] = useState(false);
    const [totalActiveBatches, setTotalActiveBatches] = useState(0);
    const [totalInactiveBatches, setTotalInactiveBatches] = useState(0);
    const [totalArchivedBatches, setTotalArchivedBatches] = useState(0);
    console.log(batches)

    // search functionality    
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        const filteredBatches = batches?.filter(
            item => item?.courseTitle?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        console.log(searchTerm)
        console.log('filter', filteredBatches)
        setChecked(filteredBatches)
    }, [searchTerm])
    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
    }

    //set Table data
    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/getBatches').then((res) => {
            let batches = res.data.batches;  //recieving batch here
            if (batches) {
                setTotalActiveBatches(batches.filter(batch => batch.status == 1 && batch.hide == 0).length)
                setTotalInactiveBatches(batches.filter(batch => batch.status == 0 && batch.hide == 0).length)
                setTotalArchivedBatches(batches.filter(batch => batch.hide == 1).length)
                batches = batches.filter(batch => batch.hide == 0)
                setBatches(batches);
                setChecked(batches);

            }
        })
    }, [loadBatches])

    console.log('Checked ', checked)
    console.log(unchecked)
    const handleChecked = (id) => {
        let temp = checked.map((data) => {
            if (id === data.id) {
                return { ...data, inputchecked: !data.inputchecked };
            }
            return data;
        });
        setChecked(temp);
    };
    const handleCheckedAll = (value) => {
        let temp = checked.map((data) => {
            return { ...data, inputchecked: value };
        });
        console.log('Temp', temp)
        setChecked(temp);
        setUnChecked(!unchecked);
    };

    const pageSize = 15;
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;
    const records = checked.slice(firstIndex, lastIndex);

    const npage = Math.ceil(checked.length / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1)
    console.log(records)
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    //handle delete course
    const handleDeleteBatches = (courseId) => {
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover this batch",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(BACKEND_SERVER_URL + '/deleteBatch/' + courseId).then((res) => {
                    if (res) {
                        swal("Poof! Your batch has been deleted!", {
                            icon: "success",
                        });
                        setBatchLoading(!loadBatches);
                    }
                })
            }
        })
    }

    const getSn = (index) => {
        return batches.length - index - ((currentPage - 1) * pageSize);
    }

    const handleBatchSave = async (batchname, batchdetails, sortId, id = 0) => {
        console.log(id)
        try {
            if (id <= 0) {
                const res = await axios.post(BACKEND_SERVER_URL + '/createBatch', { batchTitle: batchname, batchDetails: batchdetails, sortId: sortId || 0 });
                if (res?.data) {
                    console.log('Course api ', res?.data);
                    toast.success(res.data.message);
                    setBatchLoading(!loadBatches);
                }
            } else {
                const res = await axios.put(BACKEND_SERVER_URL + '/updateBatch/' + id, { batchTitle: batchname, batchDetails: batchdetails, sortId: sortId || 0 });
                if (res?.data) {
                    console.log('Course api ', res?.data);
                    toast.success(res.data.message);
                    setBatchLoading(!loadBatches);
                }
            }

        } catch (error) {
            console.error("An error occurred during the request:", error.message);

        }
    }

    const handleUploadFile = async (selectedFile) => {
        const formData = new FormData();
        formData.append('uploadedFile', selectedFile, selectedFile.name);

        axios.post(BACKEND_SERVER_URL + '/uploadBatches', formData)
            .then((response) => {
                if (response) {
                    toast.success(response.data.message);
                    setBatchLoading(!loadBatches);
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
    }

    //bulk
    /*   const deleteMultipleCourse = () => {
          let ids = records.filter(record => record.inputchecked).map(record => record.id);
          console.log('records', ids)
          if (ids.length <= 0) {
              toast.error('Please select atleast one course');
              return;
          }
          const postData = { ids: ids };
          axios.post(BACKEND_SERVER_URL + '/deleteCourses', postData).then((response) => {
              if (response) {
                  toast.success(response.data.message);
                  setCourseLoading(!loadCourses)
              }
          }).catch((error) => {
              toast.error(error.message);
          });
  
      }
      const InActivateMultipleCourse = () => {
          let ids = records.filter(record => record.inputchecked).map(record => record.id);
  
          console.log('ids', ids)
          console.log('records', records)
          if (ids.length <= 0) {
              toast.error('Please select atleast one course');
              return;
          }
          const postData = { ids: ids };
          axios.post(BACKEND_SERVER_URL + '/inactivateCourses', postData).then((response) => {
              if (response) {
                  toast.success(response.data.message);
                  setCourseLoading(!loadCourses)
              }
          }).catch((error) => {
              toast.error(error.message);
          });
  
      }
      const activateMultipleCourse = () => {
          let ids = records.filter(record => record.inputchecked).map(record => record.id);
          console.log('ids', ids)
          console.log('records', records)
          if (ids.length <= 0) {
              toast.error('Please select atleast one course');
              return;
          }
          const postData = { ids: ids };
          axios.post(BACKEND_SERVER_URL + '/activateCourses', postData).then((response) => {
              if (response) {
                  toast.success(response.data.message);
                  setCourseLoading(!loadCourses)
              }
          }).catch((error) => {
              toast.error(error.message);
          });
  
      } */
    //common bulk handler
    const commonHandler = (action) => {
        let ids = records.filter(record => record.inputchecked).map(record => record.id);
        if (ids.length <= 0) {
            toast.error('Please select atleast one batch');
            return;
        }
        const postData = { ids: ids };
        switch (action) {
            case "deleteBulk":
                swal({
                    title: "Are you sure?",
                    text:
                        "Once deleted, you will not be able to recover these batch",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/deleteBatches', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setBatchLoading(!loadBatches)
                            }
                        }).catch((error) => {
                            toast.error(error.message);
                        });

                    }
                })
                break;
            case "activateBulk":
                swal({
                    title: "Are you sure?",
                    text:
                        "Do you really want to activate these batch",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/activateBatches', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setBatchLoading(!loadBatches)
                            }
                        }).catch((error) => {
                            toast.error(error.message);
                        });

                    }
                })
                break;
            case "InActivateBulk":
                swal({
                    title: "Are you sure?",
                    text:
                        "Do you really want to inactivate these batches",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/inactivateBatches', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setBatchLoading(!loadBatches)
                            }
                        }).catch((error) => {
                            toast.error(error.message);
                        });

                    }
                })
                break;
        }
    }
    const handleTabClick = (action) => {
        if (action === "activeTab") {
            let active = batches?.filter(batch => batch.status == 1 && batch.hide == 0)
            setChecked(active)

        } else {
            let inactive = batches?.filter(batch => batch.status == 0 && batch.hide == 0);
            setChecked(inactive)

        }
    }
    return (
        <>

            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className=" page-title ">{/*  flex-wrap  */}
                                <div className='d-flex justify-content-between'>
                                    <button type="button" className="btn btn-primary "
                                        onClick={() => childRef.current.openModal("add course")}
                                    >
                                        + Add Batch
                                    </button>
                                    <button type="button" className="btn btn-primary ms-3"
                                        onClick={() => childRef.current.openModal("upload course file")}
                                    >
                                        + Import Batches
                                    </button>
                                </div>
                                <div className="input-group search-area mb-md-0 mb-3">
                                    <input type="text" className="form-control" onChange={handleSearch} value={searchTerm} placeholder="Search here..." />
                                    <span className="input-group-text"><Link to={"#"}>
                                        <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z" fill="#01A3FF" />
                                        </svg>
                                    </Link></span>
                                </div>

                            </div>
                            <div className='page-title flex-wrap  mt-1'>
                                <div className="col-sm-4">
                                    <button onClick={() => handleTabClick('activeTab')} type="button" className="btn btn-success btn-sm  me-5 ps-0 pe-3 "
                                        style={{ width: "50%", }}>
                                        Active: {totalActiveBatches}
                                    </button>
                                </div>
                                <div className="col-sm-4">
                                    <button onClick={() => handleTabClick('inActiveTab')} type="button" className="btn btn-warning btn-sm ms-1 me-5 ps-0  "
                                        style={{ width: "50%", }}>
                                        Inactive: {totalInactiveBatches}
                                    </button>
                                </div>
                                <div className="col-sm-4">
                                    <button type="button" className="btn btn-danger btn-sm ms-1 me-5 ps-0 "
                                        style={{ width: "50%" }}>
                                        Archive: {totalArchivedBatches}
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                            <div className="table-responsive full-data">
                                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">

                                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                                        <div>
                                            <Button variant="danger ms-3" size='xs' onClick={(e) => commonHandler("deleteBulk")}>Delete</Button>
                                            <Button variant="success ms-3 " size='xs' onClick={(e) => commonHandler("activateBulk")}>Active</Button>
                                            <Button variant="warning ms-3" size='xs' onClick={(e) => commonHandler("InActivateBulk")}>Inactive</Button>
                                        </div>
                                        <div className='dataTables_info'>
                                            Records: {records.length}
                                        </div>
                                        <div className="dataTables_paginate paging_simple_numbers justify-content-center"
                                            id="example-student_wrapper">
                                            <Link
                                                className="paginate_button previous disabled"
                                                to="#"
                                                onClick={prePage}
                                            >
                                                <i className="fa-solid fa-angle-left" />
                                            </Link>
                                            <span>
                                                {number.map((n, i) => (
                                                    <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                                                        onClick={() => changeCPage(n)}
                                                    >
                                                        {n}

                                                    </Link>
                                                ))}
                                            </span>
                                            <Link
                                                className="paginate_button next"
                                                to="#"
                                                onClick={nextPage}
                                            >
                                                <i className="fa-solid fa-angle-right" />
                                            </Link>
                                        </div>
                                    </div>


                                    <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0" id="example-student">
                                        <thead>
                                            <tr className='text-center'>
                                                <th>SN</th>
                                                <th>
                                                    <input type="checkbox" className="form-check-input" id="checkAll"
                                                        onClick={() => handleCheckedAll(unchecked)}
                                                    />
                                                </th>
                                                <th>Status</th>
                                                <th className='text-start'>Batch Name</th>
                                                <th className='text-start'>Description</th>
                                                <th>Priority</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.map((item, ind) => (
                                                <tr key={ind} className={`${ind % 2 == 1 ? 'table-active' : ''}`}>
                                                    <td className='text-center'><span className="text-primary font-w600   "> {getSn(ind)}</span></td>
                                                    <td className='text-center'>
                                                        <div className="checkbox me-0 align-self-center">
                                                            <div className="custom-control custom-checkbox ">
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    id={`stud-${item.id}`}
                                                                    checked={item.inputchecked}
                                                                    onChange={() => handleChecked(item.id)}
                                                                />
                                                                <label className="custom-control-label" htmlFor={`stud-${item.id}`}></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='text-center'>
                                                        {item.status == 1 && <span className="text-success font-w600 ">Active </span>}
                                                        {item.status == 0 && <span className="text-warning font-w600 ">Inactive </span>}
                                                    </td>

                                                    <td>
                                                        <div className="trans-list sweetalert">
                                                            <h4>{item.batchTitle}{' '}
                                                                <CiEdit cursor='pointer' title='Edit Course' size={20} className='ms-3' onClick={() => childRef.current.openModal("update course", item)} />
                                                                <MdDeleteOutline title='Delete Course' cursor='pointer' size={20} className='ms-2' onClick={() => { handleDeleteBatches(item.id) }}

                                                                />
                                                            </h4>
                                                        </div>
                                                    </td>
                                                    <td><h6 className="mb-0">{item.batchDetails}</h6></td>
                                                    <td className='text-center'><h6 className="mb-0">{item.sortId}</h6></td>
                                                    <td className='text-center'>
                                                        <div className="date">{new Date(item.createdOn).toLocaleDateString('en-GB')}</div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BatchModal ref={childRef} saveCallBack={handleBatchSave} uploadCallBack={handleUploadFile} />
        </>
    )
}

export default BatchManagement;