export const projectSvg1 = (
    <svg width="40" height="30" viewBox="0 0 79 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.7" d="M75.1668 20.5H68.3334V13.6667C68.3343 12.9934 68.2024 12.3266 67.9451 11.7044C67.6879 11.0822 67.3104 10.5168 66.8343 10.0407C66.3583 9.56467 65.7929 9.1872 65.1707 8.92996C64.5485 8.67272 63.8817 8.54078 63.2084 8.54168H37.1563C36.3935 8.54184 35.6403 8.37168 34.9517 8.04362C34.2631 7.71556 33.6564 7.23787 33.1759 6.64543L29.8276 2.52834C29.1893 1.73859 28.3825 1.10165 27.4661 0.664169C26.5498 0.226689 25.5472 -0.000241404 24.5317 4.56815e-06H5.12506C4.45179 -0.000894936 3.78495 0.131054 3.16275 0.38829C2.54055 0.645527 1.97521 1.023 1.49913 1.49908C1.02305 1.97516 0.645583 2.54049 0.388346 3.16269C0.131109 3.78489 -0.000838865 4.45173 6.06388e-05 5.12501V55.6404C-0.00347238 56.4099 0.147413 57.1723 0.443784 57.8824C0.740155 58.5925 1.17599 59.236 1.72548 59.7746C2.26414 60.3241 2.90758 60.7599 3.6177 61.0563C4.32781 61.3527 5.09017 61.5036 5.85965 61.5H63.3622C64.6314 61.4996 65.8665 61.0884 66.8827 60.3278C67.8988 59.5672 68.6415 58.4981 68.9997 57.2804L78.4468 24.8734C78.5942 24.3642 78.6213 23.8278 78.5259 23.3063C78.4306 22.7849 78.2154 22.2928 77.8974 21.8687C77.5793 21.4447 77.1671 21.1003 76.6933 20.8628C76.2194 20.6253 75.6968 20.5011 75.1668 20.5Z" fill="var(--primary)"/>
        <path d="M75.1645 20.5H26.6031C25.3352 20.5002 24.1016 20.9115 23.0875 21.6723C22.0733 22.4332 21.3332 23.5024 20.9784 24.7196L11.48 57.2828C11.1252 58.4994 10.3853 59.5681 9.3715 60.3285C8.35766 61.0889 7.12455 61.5 5.85724 61.5H63.3662C64.6343 61.5001 65.8682 61.0889 66.8826 60.3281C67.8971 59.5672 68.6374 58.4978 68.9923 57.2804L78.4446 24.8733C78.5932 24.3641 78.6211 23.8273 78.5262 23.3054C78.4313 22.7836 78.2162 22.2909 77.8979 21.8666C77.5797 21.4423 77.167 21.0979 76.6925 20.8607C76.2181 20.6235 75.6949 20.5 75.1645 20.5Z" fill="var(--primary)"/>
    </svg>
);

export const projectSvg2 = (
    <svg width="35" height="40" viewBox="0 0 59 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M53.1365 80H5.86371C3.13985 80 0.90918 77.7693 0.90918 75.0455V5.95453C0.90918 3.23067 3.13985 1 5.86371 1H38.3839L58.091 20.7071V75.0455C58.091 77.7693 55.8604 80 53.1365 80Z" fill="#D0E9FF" stroke="#1B87FF"></path>
        <path d="M38.5911 0.5L58.5911 20.5H38.5911V0.5Z" fill="#1B87FF"></path>
        <path d="M38.5911 0.5L58.5911 20.5H38.5911V0.5Z" stroke="#1B87FF"></path>
        <path d="M13.1365 31.4092H45.8638V35.9546H13.1365V31.4092Z" fill="#1B87FF"></path>
        <path d="M13.1365 31.4092H45.8638V35.9546H13.1365V31.4092Z" stroke="#1B87FF"></path>
        <path d="M13.1365 42.3184H45.8638V46.8638H13.1365V42.3184Z" fill="#1B87FF"></path>
        <path d="M13.1365 42.3184H45.8638V46.8638H13.1365V42.3184Z" stroke="#1B87FF"></path>
        <path d="M13.1365 53.2275H36.7729V57.773H13.1365V53.2275Z" fill="#1B87FF"></path>
        <path d="M13.1365 53.2275H36.7729V57.773H13.1365V53.2275Z" stroke="#1B87FF"></path>
    </svg>
);

export const projectSvg3 = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.2656 36.4844C21.6179 36.4844 21.0938 35.9602 21.0938 35.3125V18.9062C21.0938 18.2585 21.6179 17.7344 22.2656 17.7344C22.9134 17.7344 23.4375 18.2585 23.4375 18.9062V35.3125C23.4375 35.9602 22.9134 36.4844 22.2656 36.4844Z" fill="#4DB5FF"></path>
        <path d="M38.8281 31.7969C38.1804 31.7969 37.6562 31.2727 37.6562 30.625V14.2188C37.6562 13.571 38.1804 13.0469 38.8281 13.0469C39.4759 13.0469 40 13.571 40 14.2188V30.625C40 31.2727 39.4759 31.7969 38.8281 31.7969Z" fill="#4D97FF"></path>
        <path d="M38.5 9.49974L30.5469 11.7497L21.9375 14.1872C21.4454 14.3278 21.0938 14.7965 21.0938 15.3122V23.8903L30.5469 21.2184L40 18.5467V10.6248C40 9.85131 39.25 9.28888 38.5 9.49974Z" fill="#4DB5FF"></path>
        <path d="M8.20312 25.8594C7.55539 25.8594 7.03125 25.3352 7.03125 24.6875V1.17188C7.03125 0.524141 7.55539 0 8.20312 0C8.85086 0 9.375 0.524141 9.375 1.17188V24.6875C9.375 25.3352 8.85086 25.8594 8.20312 25.8594Z" fill="#B999FF"></path>
        <path d="M13.4846 7.23867C11.1889 5.82992 9.375 4.63828 9.375 1.17188C9.375 0.524141 8.85086 0 8.20312 0C7.55539 0 7.03125 0.524141 7.03125 1.17188V10.0723C7.03125 13.4884 9.58328 14.9692 11.8343 16.275C14.288 17.6974 16.4062 18.9266 16.4062 22.3438C16.4062 22.9915 16.9304 23.5156 17.5781 23.5156C18.2259 23.5156 18.75 22.9915 18.75 22.3438V15.3125C18.75 10.4682 15.953 8.75273 13.4846 7.23867Z" fill="#B999FF"></path>
        <path d="M4.6875 30.5469C2.10227 30.5469 0 28.4446 0 25.8594C0 23.2741 2.10227 21.1719 4.6875 21.1719C7.27273 21.1719 9.375 23.2741 9.375 25.8594C9.375 28.4446 7.27273 30.5469 4.6875 30.5469Z" fill="#B999FF"></path>
        <path d="M18.75 40C16.1648 40 14.0625 37.8977 14.0625 35.3125C14.0625 32.7273 16.1648 30.625 18.75 30.625C21.3352 30.625 23.4375 32.7273 23.4375 35.3125C23.4375 37.8977 21.3352 40 18.75 40Z" fill="#4DB5FF"></path>
        <path d="M35.2344 35.3125C32.6491 35.3125 30.5469 33.2102 30.5469 30.625C30.5469 28.0398 32.6491 25.9375 35.2344 25.9375C37.8196 25.9375 40 28.0398 40 30.625C40 33.2102 37.8196 35.3125 35.2344 35.3125Z" fill="#4D97FF"></path>
        <path d="M40 10.6249V18.5467L30.5469 21.2185V11.7498L38.5 9.49971C39.25 9.28893 40 9.85136 40 10.6249Z" fill="#4D97FF"></path>
    </svg>
);
export const projectSvg4 = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M39.984 19.984C39.984 30.832 31.36 39.664 20.592 39.968H19.472C8.672 39.68 0 30.848 0 19.968C0 9.088 8.656 0.272 19.456 0H20.576C31.36 0.32 39.984 9.136 39.984 19.984Z" fill="var(--primary)"></path>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.064 19.984C40.064 30.832 31.44 39.664 20.672 39.968H20.064V0H20.64C31.44 0.32 40.064 9.136 40.064 19.984Z" fill="var(--primary)"></path>
        <path d="M27.763 18.3285L17.7647 11.4837C17.303 11.1674 16.8378 11 16.451 11C15.7034 11 15.241 11.6 15.241 12.6044V27.398C15.241 28.4011 15.7029 29 16.4487 29C16.836 29 17.2938 28.8324 17.7566 28.5152L27.7595 21.6706C28.4028 21.2297 28.7591 20.6364 28.7591 19.9992C28.7592 19.3625 28.407 18.7693 27.763 18.3285Z" fill="white"></path>
    </svg>
);