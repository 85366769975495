import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import SampleFile from '../../../download files/course_sample_file.docx'
import { BACKEND_SERVER_URL } from '../../../http/http-config';



const BasicModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    //new course
    const [coursename, setNewCourseName] = useState('');

    const [sortId, setNewCourseSortID] = useState(0);
    const [modal, setModal] = useState("")
    const [id, setId] = useState();
    const [isCourseExisted, setIsCourseExisted] = useState(false);



    useImperativeHandle(ref, () => ({
        openModal(data, item = {}) {
            setIsCourseExisted(false);
            setNewCourseName(item?.courseTitle)
            setNewCourseSortID(item?.sortId)
            setId(item.id);
            setModal(data);
            setModalBox(true);
        }
    }));

    const handleCourseNameChange = (name) => {
        setNewCourseName(name);

        axios.post(BACKEND_SERVER_URL + '/checkExistingCourse', { courseTitle: name })
            .then((resposne) => {
                console.log(resposne.data);
                setIsCourseExisted(resposne.data.existed)
            })
            .catch((error) => {

            })
    }

    //handle create  course
    const handleSaveCourse = async () => {
        await commonCallBackForSaveUpdate();
    };
    //handle updte course
    const handleUpdateCourse = async () => {
        await commonCallBackForSaveUpdate(id);
    }

    const commonCallBackForSaveUpdate = async (id = 0) => {
        if ((coursename === "" || coursename === undefined)) {
            toast.error("Please Enter course name ");
        } else if (isCourseExisted) {
            toast.error("Course already existed for this admin")
        } else {
            await props.saveCallBack(coursename, sortId, id);
            setModalBox(false);
        }
    }


    //file handling
    const [selectedFile, setSelectedFile] = useState(null);
    // console.log(selectedFile)
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        console.log(selectedFile)

    }
    const handleUpload = async () => {
        console.log(selectedFile)
        if (selectedFile == null) {
            toast.error("Please select file")
        } else {
            await props.uploadCallBack(selectedFile);
            setModalBox(false);
            setSelectedFile(null);
        }
    }




    return (
        <>   {
            modal === "add course" &&
            <Modal onHide={setModalBox} show={modalBox} centered>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add New Course</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3 d-block">
                            <label htmlFor="basic-url" className="form-label d-block">Course Title</label>
                            <input type="text" className="form-control w-100" placeholder="Enter Course Title" onChange={(e) => handleCourseNameChange(e.target.value)} />

                            {coursename?.length == 0 && <p className='text-danger'>This field cannot be blank</p>}
                            {isCourseExisted && <p className='text-danger'>This course already exists for this admin</p>}

                        </div>
                        <div className="mb-3 d-block">
                            <label htmlFor="basic-url" className="form-label d-block">Priority</label>
                            <input value={sortId ?? 0} type="number" className="form-control w-100" placeholder="Enter sorting number for course" onChange={(e) => setNewCourseSortID(e.target.value)} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger light" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleSaveCourse}>Create</button>
                    </div>
                </div>
            </Modal>}
            {
                modal === "upload course file" &&
                <Modal onHide={setModalBox} show={modalBox} centered>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Import Courses file</h5>
                            <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 d-block">
                                <a href={SampleFile} download="sample_file.docx" ><Button variant="outline-info" size='sm' title='Click here to download the sample file'>Download Sample file </Button> </a>
                            </div>
                            <div className="mb-3 d-block">
                                <label htmlFor="basic-url" className="form-label d-block">Upload file</label>
                                <input type="file" className="form-control w-100" onChange={handleFileChange} accept=".docx" placeholder="Enter Course Title" />
                                {selectedFile == null ? < p className='text-danger'>Please select file</p> : null}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger light" onClick={() => setModalBox(false)}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleUpload}>Upload file</button>
                        </div>
                    </div>
                </Modal >}
            {
                modal === "update course" &&

                <Modal onHide={setModalBox} show={modalBox} centered>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update Course</h5>
                            <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 d-block">
                                <label htmlFor="basic-url" className="form-label d-block">Course Title</label>
                                <input type="text" className="form-control w-100" placeholder="Enter Course Title" value={coursename} onChange={(e) => handleCourseNameChange(e.target.value)} />
                            </div>
                            {!coursename && <p className='text-danger'>This field cannot be blank</p>}
                            {isCourseExisted && <p className='text-danger'>This course already exists for this admin</p>}
                            <div className="mb-3 d-block">
                                <label htmlFor="basic-url" className="form-label d-block">Priority</label>
                                <input type="number" className="form-control w-100" placeholder="Enter sorting number for course" value={sortId} onChange={(e) => setNewCourseSortID(e.target.value)} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger light" onClick={() => setModalBox(false)}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleUpdateCourse}>Update</button>
                        </div>
                    </div>
                </Modal>}
        </>
    )
})
export default BasicModal;