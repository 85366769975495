import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { IMAGES } from '../Dashboard/Content';
import ToggleButton from 'react-toggle-button'
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import TimePickerPicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { BACKEND_SERVER_URL } from '../../../http/http-config';

const AddNewTest = ({ test }) => {
    const [testName, setTestName] = useState('');
    const [paidTest, setPaidTest] = useState(1);
    //const [startTime, setStartTime] = useState('');
    // const [endTime, setEndTime] = useState('');
    const [startTime, setStartTime] = useState('00:01');
    const [endTime, setEndTime] = useState('23:59');
    const [totalTime, setTotalTime] = useState(60);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [totalMarks, setTotalMarks] = useState(0);
    const [passingMarks, setPassingMarks] = useState(0);
    const [negativeMarks, setNegativeMarks] = useState(0);
    const [priority, setPriority] = useState(0);
    const [remarks, setRemarks] = useState('');
    const [instantResult, setInstantResult] = useState(1);
    console.log(startDate)



    const intialSectionDetails = { sectionName: "", totalQuestion: 0, marksPerQestion: 0, negativeMarks: 0, passingMarks: 0 }
    const [sectionsDetails, setSectionDetails] = useState([intialSectionDetails])




    const sentionHandler = (index, field, value) => {
        //  console.log('ssccc', value, index);

        let newSection = { ...sectionsDetails[index] };

        newSection[field] = value;

        let newSectionDetails = [...sectionsDetails];
        newSectionDetails[index] = newSection;
        setSectionDetails(newSectionDetails)
    }
    const addSectionHandler = () => {
        setSectionDetails([...sectionsDetails, intialSectionDetails])
    }
    const removeSectionHandler = (index) => {
        // Create a new array without the section at the specified index
        if (sectionsDetails.length > 1) {
            const sectionsDetailsRef = [...sectionsDetails];
            sectionsDetailsRef.splice(index, 1);
            setSectionDetails(sectionsDetailsRef);
        }

    };
    //for edit test
    useEffect(() => {
        if (test) {
            console.log(JSON.parse(test.sectionDetails))

            let dateArr = test.startDate.split('/');
            setStartDate(new Date([dateArr[1], dateArr[0], dateArr[2]].join('/')));

            console.log(test.startDate)
            setTestName(test.testName);
            setPaidTest(test.paidTest);
            // setStartDate(new Date(test.startDate));
            setStartTime(test.startTime);
            dateArr = test.endDate.split('/');
            setEndDate(new Date([dateArr[1], dateArr[0], dateArr[2]].join('/')));
            // setEndDate(new Date(test.endDate));
            setEndTime(test.endTime);
            setTotalTime(test.totalTime);
            setTotalMarks(test.totalMarks);
            setPassingMarks(test.passingMarks);
            setNegativeMarks(test.negativeMarks);
            setPriority(test.priority);
            setRemarks(test.remarks);
            setInstantResult(test.instantResult);
            setSectionDetails(JSON.parse(test.sectionDetails))
        }
    }, [])
    //handle submit
    const submitTestHandler = async () => {
        console.log()
        if (!testName || !startDate || !endDate || !startTime || !endTime) {
            toast.error('Please enter all required fields');
            return;
        }

        let sections = sectionsDetails.filter(section => section.sectionName)

        axios.post(BACKEND_SERVER_URL + `${test?.id ? `/editTest/${test.id}` : '/createTest'}`, { testName, totalMarks, paidTest, passingMarks, priority, remarks, totatTime: totalTime, startTime, startDate: startDate.toLocaleDateString('en-GB'), endDate: endDate.toLocaleDateString('en-GB'), instantResult, negativeMarks, endTime, sectionsDetails: sections })
            .then((res) => {
                toast.success(res.data.message);
                //reset form
                resetTestHandler()
            })
            .catch((error) => {
                toast.error(error.message)
            })
    }
    //reset form
    const resetTestHandler = () => {
        setTestName('');
        setPaidTest(1);
        setStartTime('00:01');
        setEndTime('23:59');
        setTotalTime(60);
        setStartDate(new Date());
        setEndDate(new Date());
        setTotalMarks(0);
        setPassingMarks(0);
        setNegativeMarks(0);
        setPriority(0);
        setRemarks('');
        setInstantResult(1);
        setSectionDetails([intialSectionDetails])
    }
    //moving up and down
    const moveUpHandler = (index) => {
        if (index > 0) {
            const newSections = [...sectionsDetails]
            var temp = newSections[index]
            newSections[index] = newSections[index - 1]
            newSections[index - 1] = temp;
            setSectionDetails(newSections);
        }

    }
    //move down handler
    const moveDownHandler = (index) => {
        if (index < sectionsDetails.length - 1) {
            const newSections = [...sectionsDetails]
            var temp = newSections[index]
            newSections[index] = newSections[index + 1]
            newSections[index + 1] = temp;
            setSectionDetails(newSections);
        }

    }

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Test Details</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-9 col-lg-8">
                                    <div className="row">
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Test Name<span className="required">*</span></label>
                                                <input type="text" className="form-control" required id="exampleFormControlInput1" placeholder="Enter Test Title" value={testName} onChange={(e) => setTestName(e.target.value)} />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label text-primary">Start Date<span className="required">*</span></label>
                                                {/* <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter Test Title" value={startDate} onChange={(e) => setStartDate(e.target.value)} /> */}
                                                <br /> <DatePicker
                                                    className="form-control w-100 "
                                                    selected={startDate}
                                                    onChange={setStartDate}
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label text-primary">End Date<span className="required">*</span></label>
                                                <br />   <DatePicker
                                                    className="form-control"
                                                    selected={endDate}
                                                    onChange={setEndDate}
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Passing Marks<span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleFormControlInput3" placeholder="Enter Passing Marks" value={passingMarks} onChange={(e) => setPassingMarks(e.target.value)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Total Time<span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleFormControlInput3" placeholder="Enter Total Time" value={totalTime} onChange={(e) => setTotalTime(e.target.value)} />
                                            </div>
                                            {/*  <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Remarks<span className="required">*</span></label>
                                                <input type="text" className="form-control" id="exampleFormControlInput3" placeholder="Enter Remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)} />
                                            </div> */}
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlTextarea2" className="form-label text-primary">Remarks<span className="required"></span></label>
                                                <textarea className="form-control" id="exampleFormControlTextarea2" rows="6" value={remarks} onChange={(e) => setRemarks(e.target.value)} placeholder="Enter Remarks" />
                                            </div>

                                            {/*  <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Instant Result<span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleFormControlInput3" placeholder="Enter 1 or 0 for instant result " onChange={(e) => setInstantResult(e.target.value)} />
                                            </div> */}

                                            <div className="form-check form-switch mb-3 ">
                                                <input className="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                                    checked={instantResult}
                                                    onChange={() => setInstantResult(!instantResult)} />
                                                <label className="form-check-label text-primary" htmlFor="flexSwitchCheckDefault">Instant Result</label>
                                            </div>

                                        </div>
                                        <div className="col-xl-6 col-sm-6">
                                            {/*  <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput4" className="form-label text-primary">Paid Test <span className="required">*</span></label>

                                                <input type="number" className="form-control" id="exampleFormControlInput4" placeholder="Enter 1 or 0 for instant paid test" onChange={(e) => setPaidTest(e.target.value)} />
                                            </div> */}
                                            <div className="form-check form-switch mb-3 ">
                                                <br /> <br />
                                                <input className="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                                    checked={paidTest}
                                                    onChange={() => setPaidTest(!paidTest)} />
                                                <label className="form-check-label text-primary" for="flexSwitchCheckDefault">Paid Test</label>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput5" className="form-label text-primary">Start Time<span className="required">*</span></label>
                                                {/* <input type="text" className="form-control" id="exampleFormControlInput5" placeholder="Enter Start Time" value={startTime} onChange={(e) => setStartTime(e.target.value)} /> */}

                                                <div className='picker-data'>
                                                    <div className='color-time-picker style-1'>
                                                        <div >
                                                            <TimePickerPicker onChange={setStartTime} value={startTime} />
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                            <br /> <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">End Time<span className="required">*</span></label>
                                                {/*  <input type="text" className="form-control" id="exampleFormControlInput6" placeholder="Enter End Time" value={endTime} onChange={(e) => setEndTime(e.target.value)} /> */}
                                                <div className='picker-data'>
                                                    <div className='color-time-picker style-1'>
                                                        <div >
                                                            <TimePickerPicker onChange={setEndTime} value={endTime} />
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Negative Marks<span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleFormControlInput6" placeholder="Enter negative marks" value={negativeMarks} onChange={(e) => setNegativeMarks(e.target.value)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Total Marks<span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleFormControlInput6" placeholder="Enter Total Marks" value={totalMarks} onChange={(e) => setTotalMarks(e.target.value)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Priority</label>
                                                <input type="number" className="form-control" id="exampleFormControlInput6" placeholder="Enter your priority number" value={priority} onChange={(e) => setPriority(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-12">
                <div className="card">
                    <div className="card-header">
                        <h5 className="mb-0">Section Details</h5>
                    </div>
                    <div className="card-body">
                        {sectionsDetails?.map((section, index) => (
                            <div className="row " key={index} >
                                <div className="col-xl-1 col-sm-1 ">
                                    <div className='mb-0'>
                                        {index == 0 ? <label htmlFor="exampleFormControlInput1" className="form-label text-light"><span className="required "></span>_</label> : null}
                                        {index !== 0 ? <button className="btn btn-outline-primary me-3" onClick={() => moveUpHandler(index)}><FaArrowUp /></button> : null}
                                    </div>
                                </div>
                                <div className="col-xl-1 col-sm-1 ">
                                    <div className='mb-3'>
                                        {index == 0 ? <label htmlFor="exampleFormControlInput1" className="form-label text-light"><span className="required"></span>-</label> : null}
                                        {index !== sectionsDetails.length - 1 ? <button className="btn btn-outline-primary me-3 " onClick={() => moveDownHandler(index)}><FaArrowDown /></button> : null}
                                    </div>
                                </div>

                                <div className="col-xl-3 col-sm-3">
                                    <div className="mb-3">
                                        {index == 0 ? <label htmlFor="exampleFormControlInput1" className="form-label text-primary ">Section Name<span className="required">*</span></label> : null}
                                        <input value={sectionsDetails[index].sectionName} onChange={(e) => { sentionHandler(index, 'sectionName', e.target.value) }} type="text" className="form-control" id="exampleFormControlInput1" placeholder="Section name" />
                                    </div>
                                </div>
                                <div className="col-xl-1 col-sm-1">
                                    <div className="mb-3">
                                        {index == 0 ? <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Total Q<span className="required">*</span></label> : null}
                                        <input value={sectionsDetails[index].totalQuestion} type="number" onChange={(e) => { sentionHandler(index, 'totalQuestion', e.target.value) }} className="form-control" id="sectionDetails_" placeholder="Enter Total Marks" />
                                    </div>
                                </div>
                                <div className="col-xl-1 col-sm-1">
                                    <div className="mb-3">
                                        {index == 0 ? <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Marks/Q<span className="required">*</span></label> : null}
                                        <input value={sectionsDetails[index].marksPerQestion} type="number" onChange={(e) => { sentionHandler(index, 'marksPerQestion', e.target.value) }} className="form-control" id="sectionDetails_" placeholder="Enter Total Marks" />
                                    </div>
                                </div>
                                <div className="col-xl-1 col-sm-1">
                                    <div className="mb-3">
                                        {index == 0 ? <label htmlFor="exampleFormControlInput1" className="form-label text-primary">N. Marks<span className="required">*</span></label> : null}
                                        <input value={sectionsDetails[index].negativeMarks} type="number" onChange={(e) => { sentionHandler(index, 'negativeMarks', e.target.value) }} className="form-control" id="sectionDetails_" placeholder="Enter Total Marks" />
                                    </div>
                                </div>
                                <div className="col-xl-1 col-sm-1">
                                    <div className="mb-3">
                                        {index == 0 ? <label htmlFor="exampleFormControlInput1" className="form-label text-primary">P. Marks<span className="required">*</span></label> : null}
                                        <input value={sectionsDetails[index].passingMarks} type="number" onChange={(e) => { sentionHandler(index, 'passingMarks', e.target.value) }} className="form-control" id="sectionDetails_" placeholder="Enter Total Marks" />
                                    </div>
                                </div>
                                <div className="col-xl-1 col-sm-1 ">
                                    {sectionsDetails.length > 1 ? <div className="mb-3">
                                        {index == 0 ? <label htmlFor="exampleFormControlInput1" className="form-label text-light"> -</label> : null}
                                        <button className=' btn btn-danger' onClick={() => removeSectionHandler(index)}>Remove</button>
                                    </div> : null
                                    }
                                </div>
                            </div>
                        ))}
                        <div className="col d-flex">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label"></label>
                                <button className=' btn btn-primary text-light' type="button" onClick={addSectionHandler}>+ Add</button>

                            </div>
                        </div>
                        <div className=" d-flex justify-content-end">
                            {!test ? <button className="btn btn-outline-primary me-3" onClick={resetTestHandler}>Reset</button> : null}
                            <button className="btn btn-primary" type="button" onClick={submitTestHandler}>Save</button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default AddNewTest;