import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import CourseSampleFile from '../../../download files/course_sample_file.docx'
import CustomClearIndicator from './MultiSelectBatches';
import { BACKEND_SERVER_URL } from '../../../http/http-config';



const CourseModal = forwardRef((props, ref) => {
    console.log('coursemodal')
    const [modalBox, setModalBox] = useState(false);
    //new course
    const [coursename, setNewCourseName] = useState('');

    const [sortId, setNewCourseSortID] = useState(0);
    const [modal, setModal] = useState("")
    const [id, setId] = useState();
    console.log('courseId', id)
    const [isCourseExisted, setIsCourseExisted] = useState(false);

    //course-batch mapping
    const [mappedBatches, setMappedBatches] = useState();
    const [previouslyMappedBatchIds, setPreviouslyMappedBatchIds] = useState();
    console.log('batchesToBeReturn', mappedBatches)
    const handleUpdateBatchIds = async () => {
        const mappedBatchIds = mappedBatches.map((batch) => `${batch.id}`).toString().trim();
        console.log('mappedIds', mappedBatchIds);
        console.log('mappedIds', typeof mappedBatchIds);
        await axios.post(BACKEND_SERVER_URL + '/setBatchIds/' + id, mappedBatchIds)
            .then((response) => {
                toast.success(response.data.message)
            })
            .catch((error) => {
                toast.error(error)
            });
        setModalBox(false);
        props.setCourseLoading(!props.loadCourses)
    };



    useImperativeHandle(ref, () => ({
        openModal(data, item = {}) {
            setIsCourseExisted(false);
            setNewCourseName(item?.courseTitle);
            setNewCourseSortID(item?.sortId);
            setId(item?.id);
            setModal(data);
            setModalBox(true);
            if (item?.batches != '' && item?.batches != undefined) {
                console.log('Raaz')
                setPreviouslyMappedBatchIds(item.batches.split(',').map(Number));
            } else {
                console.log("Raz2")
                setPreviouslyMappedBatchIds(null)
            }

        }
    }));

    const handleCourseNameChange = (name) => {
        setNewCourseName(name);

        axios.post(BACKEND_SERVER_URL + '/checkExistingCourse', { courseTitle: name })
            .then((resposne) => {
                console.log(resposne.data);
                setIsCourseExisted(resposne.data.existed)
            })
            .catch((error) => {

            })
    }

    //handle create  course
    const handleSaveCourse = async () => {
        await commonCallBackForSaveUpdate();
    };
    //handle updte course
    const handleUpdateCourse = async () => {
        await commonCallBackForSaveUpdate(id);
    }

    const commonCallBackForSaveUpdate = async (id = 0) => {
        if ((coursename === "" || coursename === undefined)) {
            toast.error("Please Enter course name ");
        } else if (isCourseExisted) {
            toast.error("Course already existed for this admin")
        } else {
            await props.saveCallBack(coursename, sortId, id);
            setModalBox(false);
        }
    }


    //file handling
    const [selectedFile, setSelectedFile] = useState(null);
    // console.log(selectedFile)
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        console.log(selectedFile)

    }
    const handleUpload = async () => {
        console.log(selectedFile)
        if (selectedFile == null) {
            toast.error("Please select file")
        } else {
            await props.uploadCallBack(selectedFile);
            setModalBox(false);
            setSelectedFile(null);
        }
    }
    const setBatchesToBeReturn = (batchesToBeReturn) => {
        setMappedBatches(batchesToBeReturn)
    }



    return (
        <> {
            modal === "add course" &&
            <Modal onHide={setModalBox} show={modalBox} centered>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add New Course</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3 d-block">
                            <label htmlFor="basic-url" className="form-label d-block">Course Name</label>
                            <input type="text" className="form-control w-100" placeholder="Enter Course Name" onChange={(e) => handleCourseNameChange(e.target.value)} />

                            {coursename?.length == 0 && <p className='text-danger'>This field cannot be blank</p>}
                            {isCourseExisted && <p className='text-danger'>This course already exists for this admin</p>}

                        </div>
                        <div className="mb-3 d-block">
                            <label htmlFor="basic-url" className="form-label d-block">Priority</label>
                            <input value={sortId ?? 0} type="number" className="form-control w-100" placeholder="Enter sorting number for course" onChange={(e) => setNewCourseSortID(e.target.value)} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger light" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleSaveCourse}>Create</button>
                    </div>
                </div>
            </Modal>
        }
            {
                modal === "upload course file" &&
                <Modal onHide={setModalBox} show={modalBox} centered>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Import Courses file</h5>
                            <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 d-block">
                                <a href={CourseSampleFile} download="course_sample_file.docx" ><Button variant="outline-info" size='sm' title='Click here to download the sample file'>Download Sample file </Button> </a>
                            </div>
                            <div className="mb-3 d-block">
                                <label htmlFor="basic-url" className="form-label d-block">Upload file</label>
                                <input type="file" className="form-control w-100" onChange={handleFileChange} accept=".docx" placeholder="Enter Course Title" />
                                {selectedFile == null ? < p className='text-danger'>Please select file</p> : null}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger light" onClick={() => setModalBox(false)}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleUpload}>Upload file</button>
                        </div>
                    </div>
                </Modal >
            }
            {modal === "update course" &&
                <Modal onHide={setModalBox} show={modalBox} centered>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update Course</h5>
                            <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 d-block">
                                <label htmlFor="basic-url" className="form-label d-block">Course Title</label>
                                <input type="text" className="form-control w-100" placeholder="Enter Course Title" value={coursename} onChange={(e) => handleCourseNameChange(e.target.value)} />
                            </div>
                            {!coursename && <p className='text-danger'>This field cannot be blank</p>}
                            {isCourseExisted && <p className='text-danger'>This course already exists for this admin</p>}
                            <div className="mb-3 d-block">
                                <label htmlFor="basic-url" className="form-label d-block">Priority</label>
                                <input type="number" className="form-control w-100" placeholder="Enter sorting number for course" value={sortId} onChange={(e) => setNewCourseSortID(e.target.value)} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger light" onClick={() => setModalBox(false)}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleUpdateCourse}>Update</button>
                        </div>
                    </div>
                </Modal>
            }
            {modal === "batch_mapping" &&
                <Modal onHide={setModalBox} show={modalBox} centered>
                    <Fragment>
                        <div className="row  select-wrapper"></div>
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-4">
                                        <h4 className="card-title">Select Batches</h4>
                                    </div>
                                    <CustomClearIndicator getBatchesToBeReturn={setBatchesToBeReturn} previouslyMappedBatchIds={previouslyMappedBatchIds}></CustomClearIndicator>
                                </div>
                            </div>
                        </div>
                    </Fragment >
                    <div className="position-absolute bottom-0 start-0 m-3">
                        <Button variant='danger' size='xs' className="w-30">Cancel</Button>
                    </div>
                    <div className="position-absolute bottom-0 end-0 m-3">
                        <Button size='xs' className="w-30" onClick={handleUpdateBatchIds}>Update Batch</Button>
                    </div>
                </Modal>

            }
        </>
    )
})
export default CourseModal;